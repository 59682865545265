<template>
  <div class="lead-center-applications-renderer">
    <div v-for="(application, index) in new_applications" :key="index" class="application" @click="applicationSettings(application.key, index)">
      <div class="status-dot" :class="{'complete': application.sync_status === 'complete', 'error': application.sync_status === 'failed', 'waiting': application.sync_status === 'waiting'}"></div>
      <div class="application-code">
        <img :src="getAppIcon(application.key)" :alt="application.key" />
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationSettingsPopup from '@/agGridV2/components/application-settings-popup.component.vue'

export default {
  data() {
    return {
      new_applications: [],
      modules: [],
      platform_id: null
    };
  },
  mounted() {
    this.modules = this.params.modules.filter((m) => m.category === this.params.column.colId)
    let new_applications = this.params.data.new_applications.filter((na) => this.modules.map((m) => m.key).includes(na.key))
    if (new_applications && new_applications.length > 0) {
      new_applications = new_applications.slice().sort((a, b) => {
        if (a.sync_status !== 'complete' || a.sync_error) {
          return -1;
        }
        if (b.sync_status !== 'complete' || b.sync_error) {
          return 1;
        }
        return 0;
      });
    }
    this.new_applications = new_applications
    this.platform_id = this.params.data.platform_id
  },
  methods: {
    getAppIcon(applicationKey) {
      const module = this.modules.find((m) => m.key === applicationKey)
      return module.icon;
    },
    applicationSettings(applicationKey, applicationIndex) {
      const module = this.modules.find((m) => m.key === applicationKey)
      this.$modal.show(
        ApplicationSettingsPopup,
        {
          platform_id: this.platform_id,
          module,
          application: this.new_applications[applicationIndex]
        }, { width: '600px' }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.lead-center-applications-renderer {
  display: flex;
  gap: 5px;
  height: 100%;
  width: 100%;
}

.application {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  border: 1px solid #ccc;
  position: relative;
  width: 50px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

.status-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  right: 1px;
}

.status-dot.complete {
  background-color: green;
}

.status-dot.error {
  background-color: red;
}

.status-dot.waiting {
  background-color: yellow;
}

.application-code {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  line-height: normal;
  img {
    height: 25px;
    width: 25px;
    object-fit: contain;
  }
}

.scroll-button {
  background: none;
  border: none;
  cursor: pointer;
  user-select: none;
  z-index: 1;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.scroll-button.left {
  left: 0;
}

.scroll-button.right {
  right: 0;
}

.scroll-button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
</style>
